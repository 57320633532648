<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('secret_question.question') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col  xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('secret_question.question_namel')"
              label-for="country_name"
            >
              <b-form-input
                id="name"
                v-model="search.question_name"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('secret_question.question') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(question_name)="data">
                      {{ data.item.question_name }}
                    </template>
                    <template v-slot:cell(country_name_bn)="data">
                      {{ data.item.question_name_bn }}
                    </template>
                   <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="$t('secret_question.question_entry')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="cEditId" :key="cEditId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { secretQuestionlist, secretQuestionToggleStatus } from '../../api/routes'

export default {
  name: 'UiDataTable',
  components: {
    Form
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        slOffset: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage
      },
      search: {
        name: ''
      },
      cEditId: 0,
      rows: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.Auth.questions
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('secret_question.question_namel'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'question_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'question_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.cEditId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        country_name: '',
        country_name_bn: '',
        editable: false
      }
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.cEditId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(authServiceBaseUrl, `${secretQuestionToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('Auth/toggleQuestionStatus', item)
          this.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(authServiceBaseUrl, secretQuestionlist, params)
      //
      if (result.success) {
        this.$store.dispatch('Auth/addQuestionList', result.data.data)
      }
      this.paginationData(result.data)
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = data.per_page * data.current_page - data.per_page + 1
    }
  }
}
</script>
